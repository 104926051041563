<template>
  <div>
    <div style="padding: 0 30px">
      <el-form :model="searchForm" inline>
        <el-form-item :label="$t('应用编号')">
          <el-input
            v-model.trim="searchForm.appId"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.appId = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item :label="$t('产品名称')">
          <el-input
            v-model.trim="searchForm.productName"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.productName = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item :label="$t('启用状态')">
          <el-select
            v-model="searchForm.enabled"
            :placeholder="$t('全部状态')"
            filterable
            clearable
            @clear="searchForm.enabled = null"
            style="width: 200px"
          >
            <el-option
              v-for="(item, key) in dic.enableStatus"
              :key="key"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="query"
            ><i class="fa-solid fa-magnifying-glass"></i
            >{{ $t("搜索") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <el-card shadow="hover">
      <!-- 表格 -->
      <el-table
        :data="tableData"
        border
        height="var(--height)"
        class="elTable"
        v-loading="loading"
      >
        <template #empty>
          <el-empty description="" />
        </template>
        <el-table-column prop="appId" :label="$t('应用编号')" align="center" />
        <el-table-column
          prop="productName"
          :label="$t('产品名称')"
          align="center"
        />
        <el-table-column
          prop="price"
          :label="$t('售价') + '(元/条)'"
          align="center"
        />
        <!-- <el-table-column prop="billType" :label="$t('计费方式')" align="center">
          <template #default="scope">
            <div>
              {{ idToName(scope.row.billType, dic.billType) }}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="payType" :label="$t('付费方式')" align="center">
          <template #default="scope">
            {{ idToName(scope.row.payType, dic.payType) }}
          </template>
        </el-table-column>
        <el-table-column prop="operator" :label="$t('运营商')" align="center">
          <template #default="scope">
            {{ idToName(scope.row.operator, dic.operator) }}
          </template>
        </el-table-column>
        <el-table-column prop="extendCode" :label="$t('子号')" align="center" />
        <el-table-column :label="$t('状态')" align="center">
          <template #default="scope">
            <el-switch
              v-model="scope.row.enabled"
              size="large"
              style="--el-switch-on-color: #52c41a"
              :active-value="100"
              :inactive-value="200"
              @click.stop="switchChange(scope.row, scope.$index)"
            />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="margin: 0 auto; overflow-x: auto; padding: 20px 0">
        <el-pagination
          v-model:currentPage="searchForm.page"
          v-model:page-size="searchForm.pageSize"
          :page-sizes="[10, 50, 100, 200]"
          :disabled="false"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="query"
          @current-change="query"
        />
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  name: "appManage",
};
</script>
<script setup>
import {
  reactive,
  onBeforeMount,
  ref,
  onMounted,
  onActivated,
  computed,
} from "vue";
import { useStore } from "vuex";
import { getAppIdList, updateAppStatus } from "@/http/api/appManage";
import { ElMessage, ElMessageBox } from "element-plus";
import { idToName, onCopy, dayjs } from "@/utils/tool";
import dic from "@/utils/dictionary";
import md5 from "js-md5";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
// import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
// import en from 'element-plus/dist/locale/en.mjs'
// const language = ref('zh-cn')
// const locale = computed(() => (language.value === 'zh-cn' ? zhCn : en))
// console.log(dayjs().format());
onBeforeMount(() => {
  query();
});
const loading = ref(false);
const tableData = ref([]);
let total = ref(0);
// 查询表单
const searchForm = reactive({
  appId: "",
  productName: "",
  // billType: null,
  // payType: null,
  // operator: null,
  // extendCode: "",
  enabled: null,
  page: 1,
  pageSize: 100,
});
const query = async () => {
  loading.value = true;
  const res = await getAppIdList(searchForm);
  if (res.code === 0) {
    total.value = res.data.total;
    tableData.value = res.data.list || [];
  }
  loading.value = false;
};

const switchChange = async (row, i) => {
  let enabled = row.enabled === 100 ? 200 : 100; //记录修改前的状态
  let msg = row.enabled === 100 ? t("启用") : t("停用");
  ElMessageBox.confirm(
    `<strong style="font-weight:700">${msg}</strong><<strong style="font-weight:700">${row.productName}--${row.appId}</strong>>?`,
    t("提示"),
    {
      confirmButtonText: t("确定"),
      cancelButtonText: t("取消"),
      type: "warning",
      dangerouslyUseHTMLString: true,
    }
  )
    .then(async () => {
      const res = await updateAppStatus({
        appId: row.appId,
        action: row.enabled,
      });
      if (res.code === 0) {
        ElMessage({
          message: res.msg,
          type: "success",
        });
      } else {
        row.enabled = enabled; //修改失败，还原状态
      }
    })
    .catch(() => {
      row.enabled = enabled; //取消操作，还原状态
      ElMessage({
        type: "info",
        message: t("已取消操作"),
      });
    });
};
</script>
    
<style lang="scss" scoped>
.elTable {
  --height: calc(100vh - 230px);
}

::v-deep(label) {
  margin-bottom: 0;
}
</style>
<style lang="scss">
</style>
    